import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

//import Login from './Pages/Login/Login';
//import Challenge from './Pages/Challenge/Challenge';
//import Scoreboard from './Pages/Scoreboard/Scoreboard';
import Navigation from './Components/Navigation/Navigation';

import Challenges from './Pages/Challenges/Challenges';
import Contact from './Pages/Contact/Contact';

import Challenge1 from './Pages/Challenge1/Challenge1';
import Spelregels from './Pages/Spelregels/Spelregels';

//<Route path="scoreboard" element={<Scoreboard />} />
export default function App() {
  return (
    <div className='app' style={{height: "100vh"}}>
      <Navigation />
      <Routes>
        <Route path="/" element={<Challenges />} />
        <Route path="challenges" element={<Challenges />} />
        <Route path="challenge/*" element={<Challenge1 />} />
        <Route path="spelregels" element={<Spelregels />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      ERROR 404
    </div>
  )
}