import React from 'react'
import { Link } from 'react-router-dom';
import './Challenges.css';

import Header from '../../Components/Header/Header';
import ChallengesComponent from '../../Components/Challenges/Challenges_component';

let challenges = [
  {
    name: 'Challenge 1',
    info: 'Outlook',
    date: '------',
    active: true
  },
  {
    name: 'Challenge 2',
    info: 'Werken in de Cloud',
    date: '-------',
    active: false
  },
  {
    name: 'Challenge 3',
    info: 'SharePoint',
    date: '-------',
    active: false
  },
];
export default function Challenges() {
  return (
    <div className='main'>
      <Header title='DigiChallenge Cloudmigratie' />
      <div className='challenge'>
        <Link to="/challenge" style={{ textDecoration: 'none' }}>
          <div className='challenge-image-first'/>
          <h1 className='challenge-title'>Challenge 1</h1>
          <h2 className='challenge-subtitle'>Outlook</h2>
        </Link>
      </div>
      <div className='challenge'>
          <div className='challenge-image-second' />
          <h1 className='challenge-title'>Challenge 2</h1>
          <h2 className='challenge-subtitle'>Werken in de Cloud</h2>
      </div>
      <div className='challenge'>
          <div className='challenge-image-third' />
          <h1 className='challenge-title'>Challenge 3</h1>
          <h2 className='challenge-subtitle'>SharePoint</h2>
      </div>
    </div>
  )
}
