import React from 'react'

import Header from '../../Components/Header/Header';

import './Contact.css';

export default function Contact() {
  return (
    <div className='main'>
      <Header title="Contact" />
      <div className='challenge-body'>
        <p className='challenge1_text'>Heb je vragen over de DigiChallenge? Neem contact op! </p>
        <p className='challenge1_text'>Naam: Sven Haverkamp</p>
        <p className='challenge1_text'>E-mail:
          <a href="mailto:s.haverkamp@vva-informatisering.nl">
          s.haverkamp@vva-informatisering.nl
          </a>
        </p>
        <p className='challenge1_text'>Telefoon: 06 40 74 52 77</p>
      </div>
    </div>
  )
}
