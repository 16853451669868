import React from 'react'
import { Link } from 'react-router-dom';

import './Challenge1.css';

import Header from '../../Components/Header/Header';

export default function Challenge1() {
  return (
    <div className='main'>
      <Header title='Challenge 1' />
      <div className='challenge-body'>
        <h1>Outlook</h1>
        <div className='challenge-body-links'>
          <Link to="/challenge">Uitleg</Link>
          <Link to="/Spelregels">Spelregels</Link>
        </div>
        <p className='challenge1_text'>De komende periode ga jij, aan de hand van uitdagende challenges, spelenderwijs leren over een aantal veranderingen die de migratie naar de cloud met zich meebrengt. De DigiChallenge is onderdeel van de  inloopsessies die alle medewerkers van Woongoed gaan volgen. Doel is dat alle medewerkers voldoende getraind zijn in de nieuwe manier van werken.</p>
        <p className='challenge1_text'>De eerste DigiChallenge staat in het teken van Outlook! </p>
        <p className='challenge1_text'>Outlook ken je natuurlijk al en gebruik je al jaren voor mailen,  beheren van je agenda. Misschien werk je ook met contactpersonen en met taken. Gelukkig gaat er met de nieuwe versie niet heel veel veranderen. Zo blijft het scherm er vrijwel hetzelfde uitzien en ook in de werking verandert er weinig. Toch zijn er een aantal belangrijke dingen om te weten. Zodat je Outlook op de juiste manier gebruikt en het programma goed blijft werken. We staan uitgebreid stil bij de dingen die je moet weten. En daarnaast delen we handige tips om slimmer te werken met Outlook! </p>
        <p className='challenge1_text'>Stap voor stap nemen we je mee door de nieuwe werkwijze. Aan de hand van 5 modules doorloop je de Digichallenge. Na afloop ben je helemaal op de hoogte en klaar voor het gebruik van Outlook Exchange Online. En vergeet niet om de geheime missie te volbrengen! Modules DigiChallenge Outlook Exchange Online: </p>
        <p className='challenge1_text'>Klik rechts op de start-knop om naar de DigiChallenge te gaan. Voordat je begint krijg je alles haarfijn uitgelegd. Veel succes en plezier!</p>
        <a href="https://form.typeform.com/to/GSc8c7kx">
          <button className='challenge_button'>Naar de challenge </button>
        </a>
      </div>
    </div>
  )
}
