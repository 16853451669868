import React from 'react'
import { Link } from 'react-router-dom';

import './Spelregels.css';

import Header from '../../Components/Header/Header';

export default function Spelregels() {
  return (
    <div className='main'>
      <Header title='Challenge 1' />
      <div className='challenge-body'>
        <h1>Outlook</h1>
        <div className='challenge-body-links'>
          <div className=''></div>
          <Link to="/challenge" className='challenge-body-links-inactive'>Uitleg</Link>
          <Link to="/Spelregels" className='challenge-body-links-active'>Spelregels</Link>
        </div>
        <p className='challenge1_text'>Wat houden de DigiChallenges in? We leggen het je uit!</p>
        <p className='challenge1_text'>De veranderingen van de cloudmigratie zijn opgedeeld in 4 uitdagende DigiChallenges die de komende maanden door alle medewerkers worden gedaan. Elke DigiChallenge bestaat uit filmpjes, opdrachten en quizvragen. Daarmee leer je stap voor stap over wat er gaat veranderen en ga je oefenen met de nieuwe manier van werken. Maar dat is niet alles. Want naast dat je gaat leren en oefenen ga bij je bij elke DigiChallenge aan de slag met een geheime missie. Namelijk het kraken van een code! </p>
        <p className='challenge1_text'>Je kraakt de code door tijdens het uitvoeren van de opdrachten (3 in totaal) te speuren naar letters, cijfers en leestekens. Alle opdrachten en quizjes moeten worden voltooid om de code te achterhalen. Heb je alle letters, cijfers en leestekens verzameld? Dan ben je nog slechts 1 stap verwijderd van het kraken van de code. Lukt het jou om alle opdrachten en quizen met succes uit te voeren, de code te kraken en de geheime missie te volbrengen? Dan ontvang jij na elke challenge een heus certificaat! </p>
        <p className='challenge1_text'>Je speelt de challenge zelfstandig om jezelf te trainen in de nieuwe werkwijze. Elke DigiChallenge is opgedeeld in modules. Na afronding van een module krijg je de optie om door te gaan naar de volgende module of de module nogmaals te oefenen. Zodat je ervoor kunt zorgen dat je de nieuwe werkwijze goed beheerst! </p>
        <p className='challenge1_text'>Heb je een vraag? Neem contact op met Frans of Michel! </p>
        <Link to="/challenge"><button className='challenge_button'>Terug </button></Link>
      </div>
    </div>
  )
}
