import React from 'react'
import { Link } from 'react-router-dom';

import './Navigation.css';

export default function Navigation() {
    return (
        <div className='nav'>
            <div className='nav-profile'>
                <img src='https://www.woongoed.nl/uiCustom/woongoed/images/logo-woongoed.svg' alt='profile' />
                <h1>Hi, Woongoed</h1>
                <h2><p>Speel nu challenge 1!</p></h2>
            </div>
            <div className='nav-links'>
                <Link to="/challenges">Challenges</Link>
                <Link to="/contact">Contact</Link>
            </div>
            <div className='nav-settings'>
                <img src='/VVA-logo.svg' alt='VVA' />
            </div>
        </div>
    )
}

//            <div className='nav-links'>
//<Link to="/dashboard">Dashboard</Link>
//<Link to="/challenges">Challenges</Link>
//<Link to="/scoreboard">Scoreboards</Link>
//<Link to="/contact">Contact</Link>
//</div>
//<div className='nav-settings'>
//<Link to="/help">Help</Link>
//<Link to="/settings">Settings</Link>
//<Link to="/log-out">Log out</Link>
//<img src='/VVA-logo.svg' alt='VVA' />
//</div>